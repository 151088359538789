import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {updateSubscriberField} from "../../actions/subscription";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";

export default function SignUp () {

    const subscriber = useSelector(state => state.subscription.subscriber);
    const unitedStates = useSelector(state => state.unitedStates.states);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleFieldChange = function (e) {
        if (e.target.name === "country") return;
        dispatch(updateSubscriberField(e.target.name, e.target.value));
    }

    const handleValidateForm = function () {
        if (!subscriber.firstname || !subscriber.lastname || !subscriber.address || !subscriber.city || !subscriber.state || !subscriber.zip || !subscriber.email  || !subscriber.applicationUrl) {
            toast.error("Required fields are marked with a *");
        }
        navigate("/subscribe");
    }

    return (<div className={"row justify-content-center"}>
        <div className={"col-8 mt-5"}>
            <div className="card border-0 shadow my-5">
                <div className="card-header p-3 h4 text-center">
                    Sign up for Appsparc 1031 Exchange
                </div>
                <div className="card-body p-2">
                    <div className="mb-1">
                        <label className="form-label">Company Name</label>
                        <input name="company" className="form-control form-control-sm" value={subscriber.company}
                               onChange={handleFieldChange}/>
                    </div>
                    <div className={"row m-0 p-0"}>
                        <div className={"col m-0 p-0 me-1"}>
                            <div className="mb-1">
                                <label className="form-label">First Name*</label>
                                <input name="firstname" className="form-control form-control-sm"
                                       value={subscriber.firstname}
                                       onChange={handleFieldChange}/>
                            </div>
                        </div>
                        <div className={"col m-0 p-0 me-1"}>
                            <div className="mb-1">
                                <label className="form-label">Last Name*</label>
                                <input name="lastname" className="form-control form-control-sm"
                                       value={subscriber.lastname}
                                       onChange={handleFieldChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="mb-1">
                        <label className="form-label">Address*</label>
                        <input name="address" className="form-control form-control-sm"
                               value={subscriber.address} onChange={handleFieldChange}/>
                    </div>
                    <div className={"row m-0 p-0"}>
                        <div className={"col m-0 p-0 me-1"}>
                            <div className="mb-1">
                                <label className="form-label">City*</label>
                                <input name="city" className="form-control form-control-sm"
                                       value={subscriber.city} onChange={handleFieldChange}/>
                            </div>
                        </div>
                        <div className={"col m-0 p-0 me-1"}>
                            <div className="mb-1">
                                <label className="form-label">State*</label>
                                <select name="state" className="form-select form-select-sm" onChange={handleFieldChange}
                                        value={subscriber.state}>
                                    <option disabled value="">Select a State</option>
                                    {unitedStates.map((us, idx) => <option key={idx}
                                                                           value={us.name}>{us.name}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className={"col m-0 p-0"}>
                            <div className="mb-1">
                                <label className="form-label">Zip*</label>
                                <input name="zip" className="form-control form-control-sm"
                                       value={subscriber.zip} onChange={handleFieldChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="mb-1">
                        <label className="form-label">Email*</label>
                        <input name="email" className="form-control form-control-sm" value={subscriber.email}
                               onChange={handleFieldChange}/>
                    </div>
                    <div className="mb-1">
                        <label className="form-label">Phone Number</label>
                        <input name="phoneNumber" className="form-control form-control-sm"
                               value={subscriber.phoneNumber} onChange={handleFieldChange}/>
                    </div>
                    <div className="mb-1">
                        <label className="form-label">Application URL*</label>
                        <div className={"input-group input-group-sm"}>
                            <div className="input-group-prepend">
                                <span className="input-group-text">https://</span>
                            </div>
                            <input name="applicationUrl" className="form-control"
                                   value={subscriber.applicationUrl} onChange={handleFieldChange}/>
                            <div className="input-group-append">
                                <span className="input-group-text">.appsparc.com</span>
                            </div>
                        </div>

                    </div>
                    <div className={"row mt-4"}>
                        <div className={"col text-end"}>
                        <button type={"button"} className={"btn btn-sm btn-outline-primary"} onClick={handleValidateForm}>Proceed to Payment
                                Information
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}
