import React from "react";
import {Link} from "react-router-dom";

const Profile = () => {

    const signout = () => {


    }

    return (
        <div className="row m-0 p-0 justify-content-center">
        <div className="col-5">
            <div className="card border-0 shadow my-2">
                <div className="card-header p-1">
                    <div className={"row m-0 p-0"}>
                        <div className={"col-9 m-0 p-0 pt-2 ps-2"}>
                            <h5>User Profile</h5>
                        </div>
                        <div className={"text-end col-3 m-0 p-0"}>
                            <button className={"btn btn-link text-dark"} onClick={signout}>Sign Out</button>
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    <Link className={"btn btn-outline-dark py-5 px-5"} to={"/userlist"}>Registrations</Link>
                </div>
            </div>
        </div>
    </div>)
}

export default Profile;