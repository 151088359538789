import React from 'react';
import {Link} from "react-router-dom";

export default function TenThirtyOneExchange () {
    return (<div className="container-lg vh-75 bg-body rounded-2" style={{marginTop: '65px'}}>
        <div className={"row m-2"}>
            <div className={"col py-2"}>
                <div className={"row"}>
                    <div className={"col-8"}>
                        <h4>Streamline Your 1031 Exchange Transactions with Appsparc</h4>
                    </div>
                    <div className={"col-4 text-end"}><Link to={"/signup"} className={"btn btn-sm btn-primary"}>Sign up for Appsparc</Link> </div>
                </div>
                <div className={"p-3"}>
                    As a Qualified Intermediary (QI), managing 1031 exchange transactions can be a complex and demanding
                    task. Appsparc is a specialized software tool designed to simplify this process, providing a secure
                    and efficient platform for QIs to track and manage these transactions with ease.
                </div>
            </div>
        </div>
        <div className={"row m-2"}>
            <div className={"col py-2"}>
                <h4>Secure Document Management</h4>
                <div className={"p-3"}>Appsparc offers a centralized and secure repository for storing and accessing critical documentation
                related to 1031 exchanges, such as property information, and exchange agreements. This ensures all
                    relevant documents are readily available and protected from unauthorized access.</div>
            </div>
        </div>
        <div className={"row m-2"}>
            <div className={"col py-2"}>
                <h4>Timeline and Deadline Tracking</h4>
                <div className={"p-3"}>Stay on top of important timelines and deadlines with Appsparc's intuitive tracking system. Monitor the
                45-day identification period and the 180-day safe harbor period with ease, ensuring that all
                    requirements are met within the specified timeframes.</div>
            </div>
        </div>
        <div className={"row m-2"}>
            <div className={"col py-2"}>
                <h4>Efficient Transaction Management</h4>
                <div className={"p-3"}>Manage multiple 1031 exchange transactions simultaneously with Appsparc's centralized platform. Track
                the progress of each exchange efficiently, enhancing your productivity and streamlining your operations.</div>
            </div>
        </div>
        <div className={"row m-2"}>
            <div className={"col py-2"}>
                <h4>Automated Reminders and Notifications</h4>
                <div className={"p-3"}>Never miss a critical deadline or milestone again. Appsparc can be configured to send automated
                reminders and notifications to you and your clients, ensuring transparency and keeping all parties
                    informed throughout the exchange process.</div>
            </div>
        </div>
        <div className={"row m-2"}>
            <div className={"col py-2"}>
                <h4>Flexible and Cost-Effective Pricing</h4>
                <div className={"px-3 pb-2"}>Unlock significant cost savings by choosing one of our annual pricing
                    plans, designed to provide predictable monthly fees and unbeatable value for your business.
                </div>
                <div className={"px-3 pb-2 mb-3"}>Experience the efficiency and peace of mind that Appsparc brings to your
                    1031 exchange transactions. <Link to="/contact">Contact us</Link> today to learn more about our software and pricing options
                    and take the first step towards streamlining your operations.
                </div>
                <div className={"col-6 offset-3"}>
                    <h5 className={"text-secondary"}>Annual Pricing Plans</h5>
                    <table className="table table-condensed table-bordered">
                        <thead>
                        <tr>
                            <th>
                                Number of Files (annually)
                            </th>
                            <th>
                                Cost (monthly)
                            </th>
                            <th>
                                Cost per File
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>100</td>
                            <td className={"text-end"}>$900</td>
                            <td className={"text-end"}>$108</td>
                        </tr>
                        <tr>
                            <td>101-150</td>
                            <td className={"text-end"}>$1,267</td>
                            <td className={"text-end"}>$108 first 100<br/>$88 101-150</td>
                        </tr>
                        <tr>
                            <td>151-200</td>
                            <td className={"text-end"}>$1,548</td>
                            <td className={"text-end"}>$108 first 100<br/>$88 101-150<br/>$68 151-200</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>);
}
