import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    clearContactForm,
    savingContactInfo,
    submitContactInfo,
    updateContactField,
    validateContactEmail
} from "../../actions/contact";
import {useNavigate} from "react-router-dom";


const Contact = () => {

    const dispatch = useDispatch();
    const contactInfo = useSelector(state => state.contact);
    const navigate = useNavigate();
    const [agree, setAgree] = useState(false);

    useEffect(() => {
        dispatch(clearContactForm());
    }, [dispatch]);

    const handleFieldChanged = function (e) {
        dispatch(updateContactField(e.target.name, e.target.value));
    }

    const submitContact = async function (e) {
        e.preventDefault();
        dispatch(savingContactInfo());
        dispatch(validateContactEmail());
        await dispatch(submitContactInfo(contactInfo));
        navigate("/");
    }

    return (
        <div className="row m-0 p-0 pt-5 justify-content-md-center">
            <div className="col-md-6">
                <div className="card border-0 shadow my-5" >
                    <div className="card-header p-3 h4">
                        Appsparc Beta Program
                    </div>
                    <div className="card-body p-2">
                        <h5 className={"pt-2"}>We're excited to announce the upcoming beta release of our 1031 exchange tracking software!</h5>
                        <p className={" my-5"}>
                            If you're interested in being one of the first to try it out and provide feedback, please fill out the form with your contact information. As a thank you, you will receive exclusive early access to the software. Sign up now!
                        </p>
                        <div className="form-group">
                            <label className="form-control-label">Name</label>
                            <input className="form-control" name="name" onChange={handleFieldChanged} value={contactInfo.name}/>
                        </div>
                        <div className="form-group">
                            <label className="form-control-label">Email</label>
                            <input type={"email"} className={contactInfo.emailValid ? "form-control" : "form-control border-danger"} name="email" onChange={handleFieldChanged} value={contactInfo.email}/>
                        </div>
                        <div className="form-group">
                            <label className="form-control-label">Phone</label>
                            <input className="form-control" name="phone" onChange={handleFieldChanged} value={contactInfo.phone}/>
                        </div>
                        <div className="form-check small mt-2">
                            <label className="form-check-label">
                                <input type={"checkbox"} className="form-check-input" onChange={(e) => setAgree(!agree)} value={agree || false} />
                                <span className={"small"}>
                                I agree to the Appsparc LLC&nbsp;
                                <a href={"https://app.termly.io/document/privacy-policy/ed6551da-40eb-4c19-a782-1a6b89428cb4"} target={"_blank"} rel="noreferrer">privacy policy</a>,&nbsp;
                                <a href={"https://app.termly.io/document/acceptable-use-policy/adf563c7-f8a0-48a2-8c6c-cfa906e68f24"} target={"_blank"} rel="noreferrer">acceptable use policy</a>,&nbsp;and&nbsp;
                                <a href={"https://app.termly.io/document/terms-of-use-for-saas/0f867f28-9c6a-43b2-97f5-ede52e620647"} target={"_blank"} rel="noreferrer">terms and conditions.</a></span></label>
                        </div>
                        <div className="form-group">
                            <div className={"row p-0 m-0"}>
                                <div className={"col-11 p-0 m-0 pt-3 ps-3"}>
                                    {contactInfo.emailValid ? <span>&nbsp;</span> : <span className={"text-danger"}>Please enter a valid email address</span> }
                                </div>
                                <div className={"col-1 p-0 m-0"}>
                                    <button className={!agree ? "btn btn-warning float-end mt-2 btn-disabled" : contactInfo.saving ? "btn btn-warning float-end mt-2 btn-disabled" : "btn btn-warning float-end mt-2"} onClick={submitContact}>
                                        {contactInfo.saving ? <span>Submitting</span> : <span>Submit</span> }
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Contact;
