import React from 'react';
import {createRoot} from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.css';
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { getConfig } from "./config";
import {Auth0Provider} from "@auth0/auth0-react";
import axios from "axios";

// Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    ...(config.audience ? { audience: config.audience } : null),
    redirectUri: window.location.origin
};

axios.interceptors.request.use(
    config => {
        // Do something before request is sent
        config.headers["Authorization"] = "Bearer " + store.getState().session.token;
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <Auth0Provider {...providerConfig}>
            <Provider store={store}>
                <Router>
                    <App />
                </Router>
            </Provider>
        </Auth0Provider>
    </React.StrictMode>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();