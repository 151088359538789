import {
    CLEAR_SESSION, CLEAR_WAITING_ARRAY, HIDE_WAITING, LOG_OUT, SET_SESSION, SHOW_WAITING
} from './action-types';

export const clearSession = () => {
    return dispatch => {
        dispatch({type: CLEAR_SESSION});
    }
}

export const setToken = token => {
    return dispatch => {
        dispatch({type: SET_SESSION,
            payload: token});
    }
}

export const logOut = function () {
    return dispatch => {
        dispatch({type: LOG_OUT });
    }
}



export const showWaiting = function (waitingId) {
    return dispatch => {
        dispatch({type: SHOW_WAITING, payload: waitingId});
    }
}

export const hideWaiting = function (waitingId) {
    return dispatch => {
        dispatch({type: HIDE_WAITING, payload: waitingId});
    }
}

export const clearWaitingArray = function () {
    return dispatch => {
        dispatch({type: CLEAR_WAITING_ARRAY});
    }
}
