export const CLEAR_SESSION = 'session/CLEAR_SESSION';
export const SET_SESSION = 'session/SET_SESSION';
export const LOG_OUT = "session/LOG_OUT";
export const SHOW_WAITING = "session/SHOW_WAITING";
export const HIDE_WAITING = "session/HIDE_WAITING";
export const CLEAR_WAITING_ARRAY = "session/CLEAR_WAITING_ARRAY";

export const CLEAR_CONTACT_FORM = "contact/CLEAR_CONTACT_FORM";
export const UPDATE_CONTACT_FIELD = "contact/UPDATE_CONTACT_FIELD";
export const VALIDATE_CONTACT = "contact/VALIDATE_CONTACT";
export const FORM_SUBMITTED = "contact/FORM_SUBMITTED";
export const LOAD_REGISTRATIONS = "contact/LOAD_REGISTRATIONS";
export const SAVING_CONTACT_INFO = "contact/SAVING_CONTACT_INFO";

export const SUBSCRIBER_FIELD_CHANGE = "subscription/SUBSCRIBER_FIELD_CHANGE";
export const SUBSCRIBE = "subscription/SUBSCRIBE";
export const SAVE_SUBSCRIBER = "subscription/SAVE_SUBSCRIBER";
