import {
    CLEAR_CONTACT_FORM, FORM_SUBMITTED, LOAD_REGISTRATIONS, SAVING_CONTACT_INFO,
    UPDATE_CONTACT_FIELD, VALIDATE_CONTACT
} from '../actions/action-types';

const initialState = {
    name: "",
    email: "",
    phone: "",
    emailValid: true,
    submitted: false,
    registrations: [],
    saving: false
};

const contact = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case CLEAR_CONTACT_FORM:
            newState = Object.assign({}, initialState);
            return newState;

        case UPDATE_CONTACT_FIELD:
            newState = Object.assign({}, state);
            newState[action.payload.field] = action.payload.value;
            return newState;

        case VALIDATE_CONTACT:
            newState = Object.assign({}, state);
            newState.emailValid = action.payload;
            newState.saving = newState.saving && action.payload;
            return newState;

        case FORM_SUBMITTED:
            newState = Object.assign({}, state);
            newState.submitted = true;
            return newState;

        case LOAD_REGISTRATIONS:
            newState = Object.assign({}, state);
            newState.registrations = action.payload;
            return newState;

        case SAVING_CONTACT_INFO:
            newState = Object.assign({}, state);
            newState.saving = true;
            return newState;

        default:
            return state
    }
}

export default contact