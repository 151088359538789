import { createStore, applyMiddleware, compose, combineReducers } from '@reduxjs/toolkit';
import session from "./session";
import contact from "./contact";
import thunk from "redux-thunk";
import unitedStates from "./united-states";
import subscription from "./subscription";

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({
  session,
  contact,
  unitedStates,
  subscription
});

export const store = createStore(rootReducer,
    storeEnhancers(applyMiddleware(thunk)));
