import React from 'react';
import AppRoutes from "./features/routes/app-routes";
import Menu from "./features/menu/menu";

function App() {
  return (
    <div>
      <Menu/>
      <div className={"container-xxl"}>
        <AppRoutes/>
      </div>
        <div className="footer bg-primary p-1 small position-fixed bottom-0 w-100">
            <div className="container text-light">
                <div className={"row m-0 p-0"}>
                    <div className={"col-2"}>
                        <span>&copy; {new Date().getFullYear()} <a className={"link-light"} href={"https://appsparc.com"} target={"_blank"} rel="noreferrer">Appsparc, LLC.</a></span>
                    </div>
                    <div className={"col-2 offset-8"}>
                        <a className={"link-light"} href={"https://app.termly.io/document/disclaimer/a8436cd0-6ed9-4a13-8035-6bacbe5d0297"} target={"_blank"} rel="noreferrer">Disclaimer</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default App;
