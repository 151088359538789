import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {clearWaitingArray} from "../../actions/session";

export default function WaitingIndicator() {
    const waiting = useSelector(state => state.session.waiting);
    const dispatch = useDispatch();

    return (<div className={waiting.length > 0 ? "modal fade show d-block" : "modal fade"} tabIndex="-1"
                 style={waiting.length > 0 ? {backgroundColor: 'rgba(0,0,0,.50)'} : {}}>
        <div className="modal-dialog-centered d-inline-block top-50 m-auto">
            <div className="modal-content d-inline-block top-50 m-auto"
                 style={{top: "45%", left: "45%", position: "absolute", width: "132px"}}>
                <div className="spinner-border m-5" role="status" onDoubleClick={() => dispatch(clearWaitingArray())}>
                </div>
            </div>
        </div>
    </div>);
}
