import React from "react";
import blogger from "../../images/files.png";
import {Link} from "react-router-dom";
import cabar from "../../images/StBarofCA.png";
import fea from "../../images/FEA-1.png";
import car from "../../images/CAAssocofRealtors.jpg";
import ces from "../../images/CES-1031-1.png";
import eic from "../../images/eiclogo.png";
import cea from "../../images/CA-Escrow-Assoc.jpg";

export default function Home () {
    return (<div className={"pt-5"}>
        <div className="container-fluid">
            <div className="py-5 text-light text-center">
                <div className="col-lg-7 mx-auto">
                    <h1 className="display-5 fw-bold">The Leading 1031 Exchange Software</h1>
                </div>
            </div>
        </div>
        <div className="container-lg">
            <div className="row mb-5 justify-content-center">
                <div className="col-8">
                    <div className="card frontpage-card">
                        <img src={blogger} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <h5 className="card-title">1031 Exchange Tracking</h5>
                            <p className="card-text">Appsparc is a software tool for Qualified Intermediaries (QIs) that helps track and manage 1031 exchange transactions. It provides a secure and efficient platform for QIs to store and access important documentation, such as property information, exchange agreements, and tax returns. The software streamlines the 1031 exchange process, allowing QIs to keep track of the timeline, deadlines, and requirements of each transaction. Additionally, it helps to ensure that all transactions comply with IRS regulations.</p>
                        </div>
                        <div className={"card-footer border-0"}>
                            <Link to="/contact" className="btn btn-warning float-end">Check out our beta program</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={"container-fluid"}>
            <div className="bg-light p-5" style={{"minHeight": "200px"}}>
                <div className="row justify-content-center">
                    <a className="col footer-link" href="https://www.calbar.ca.gov/" target="_blank" rel="noreferrer"><span><img className="img-fluid" src={cabar} alt="..."/></span></a>
                    <a className="col footer-link" href="https://www.1031.org/" target="_blank" rel="noreferrer"><span><img className="img-fluid" src={fea} alt="..."/></span></a>
                    <a className="col footer-link" href="https://www.car.org/" target="_blank" rel="noreferrer"><span><img className="img-fluid" src={car} alt="..."/></span></a>
                    <a className="col footer-link" href="https://1031ces.org/" target="_blank" rel="noreferrer"><span><img className="img-fluid" src={ces} alt="..."/></span></a>
                    <a className="col footer-link" href="https://www.escrowinstitute.org" target="_blank" rel="noreferrer"><span><img className="img-fluid" src={eic} alt="..."/></span></a>
                    <a className="col footer-link" href="https://www.ceaescrow.org/" target="_blank" rel="noreferrer"><span><img className="img-fluid" src={cea} alt="..."/></span></a>
                </div>
            </div>
        </div>
    </div>);
}
