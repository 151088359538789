import {
    CLEAR_SESSION, CLEAR_WAITING_ARRAY, HIDE_WAITING,
    LOG_OUT,
    SET_SESSION, SHOW_WAITING
} from '../actions/action-types';

const initialState = {
    username: "",
    name: "",
    email: "",
    nickname: "",
    waiting: []
}

const removeWaitingId = function (waitingArray, waitingId) {
    let i = 0;
    while (i < waitingArray.length) {
        if (waitingArray[i] === waitingId) {
            waitingArray.splice(i, 1);
        } else {
            ++i;
        }
    }
    return waitingArray;
}

const session = (state = initialState, action) => {
    let newState, waitingArray;
    switch (action.type) {
        case SET_SESSION:
            newState = Object.assign({}, state, action.payload);
            newState.nickname = newState.name.substr(0, newState.name.indexOf(" "));
            return newState;

        case CLEAR_SESSION:
            newState = Object.assign({}, initialState);
            return newState;

        case LOG_OUT:
            newState = Object.assign({}, initialState);
            return newState;

        case SHOW_WAITING:
            newState = Object.assign({}, state);
            waitingArray = Object.assign([], state.waiting);
            waitingArray.push(action.payload);
            newState.waiting = waitingArray;
            return newState;

        case HIDE_WAITING:
            newState = Object.assign({}, state);
            waitingArray = Object.assign([], state.waiting);
            newState.waiting = removeWaitingId(waitingArray, action.payload);
            return newState;

        case CLEAR_WAITING_ARRAY:
            newState = Object.assign({}, state);
            waitingArray = Object.assign([], initialState.waiting);
            newState.waiting = waitingArray;
            return newState;


        default:
            return state
    }
}

export default session
