import {SAVE_SUBSCRIBER, SUBSCRIBE, SUBSCRIBER_FIELD_CHANGE} from "./action-types";
import toast from "react-hot-toast";
import {ContactApi} from "../api";
import {baseUrl} from "../constants/settings";
import {getWaitingSpinnerId} from "../utilities/session-helpers";
import {hideWaiting, showWaiting} from "./session";

const contactApi = new ContactApi(undefined, baseUrl);

export const updateSubscriberField = (field, value) => {
    if (field === "applicationUrl" && value.length > 30)
        value = value.substr(0, 30);
    if (field === "phoneNumber")
        value = value.replace(/[^[0-9]/gi, '');
    return dispatch => {
        dispatch({type: SUBSCRIBER_FIELD_CHANGE, payload: {field, value}});
    }
}

export const subscribe = (subscriber, paymentData, navigate) => {
    return async (dispatch) => {
        const waitingId = getWaitingSpinnerId();
        try {
            dispatch(showWaiting(waitingId));
            const response = await contactApi.apiContactSubscribePost(subscriber);
            if (response.data > 0) {
                paymentData.subscriberId = response.data;
                dispatch({ type: SAVE_SUBSCRIBER, payload: paymentData.subscriberId });
                const pmtResponse = await contactApi.apiContactSetupPaymentPost(paymentData);
                dispatch({ type: SUBSCRIBE, payload: pmtResponse.data.id});
                dispatch(hideWaiting(waitingId));
                navigate("/success");
            } else {
                toast.error("Error saving subscriber data");
                dispatch(hideWaiting(waitingId));
            }
        } catch(err) {
            const errorMessage = err.response?.data?.message;
            dispatch(hideWaiting(waitingId));
            if(errorMessage){
                toast.error(errorMessage);
            } else {
                toast.error(err.message);
            }

            console.log(err);
        }
    }
}
