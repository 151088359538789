import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

export default function SubscribeSuccess () {

    const subscriber = useSelector(state => state.subscription.subscriber);
    const navigate = useNavigate();

    useEffect(() => {
        if (!subscriber?.email || subscriber.email.length === 0) {
            navigate("/signup");
        }
    }, [navigate, subscriber]);

    return (<div className={"row justify-content-center"}>
        <div className={"col-8 mt-5"}>
            <div className="card border-0 shadow my-5">
                <div className="card-header p-3 h4 text-center">
                    Your Subscription has been set up successfully.
                </div>
                <div className="card-body p-2">
                    <div className={"row mb-4"}>
                        <div className={"col"}>
                            <h5>Subscriber Information</h5>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            Subscriber Id:
                        </div>
                        <div className={"col"}>
                            {subscriber.subscriberId}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            Company:
                        </div>
                        <div className={"col"}>
                            {subscriber.company}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            Name:
                        </div>
                        <div className={"col"}>
                            {subscriber.firstname} {subscriber.lastname}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            Phone Number:
                        </div>
                        <div className={"col"}>
                            {subscriber.phoneNumber}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            Email Address:
                        </div>
                        <div className={"col"}>
                            {subscriber.email}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            Phone Number:
                        </div>
                        <div className={"col"}>
                            {subscriber.phoneNumber}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            Address:
                        </div>
                        <div className={"col"}>
                            {subscriber.address}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            &nbsp;
                        </div>
                        <div className={"col"}>
                            {subscriber.city}, {subscriber.state} {subscriber.zip}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            Country:
                        </div>
                        <div className={"col"}>
                            {subscriber.country}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            Application URL:
                        </div>
                        <div className={"col"}>
                            https://{subscriber.applicationUrl}.appsparc.com
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}
