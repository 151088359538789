import {
    LOG_OUT, SAVE_SUBSCRIBER,
    SUBSCRIBE, SUBSCRIBER_FIELD_CHANGE
} from '../actions/action-types';

const initialState = {
    subscriber: {
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
        address: "",
        city: "",
        state: "",
        company: "",
        country: "US",
        zip: "",
        subscriberId: "",
        applicationUrl: "",
        terms: false
    }
}

const subscription = (state = initialState, action) => {
    let newState, subscriber;
    switch (action.type) {
        case LOG_OUT:
            newState = Object.assign({}, initialState);
            return newState;

        case SUBSCRIBER_FIELD_CHANGE:
            newState = Object.assign({}, state);
            subscriber = Object.assign({}, newState.subscriber);
            subscriber[action.payload.field] = action.payload.value;
            newState.subscriber = subscriber;
            return newState;

        case SUBSCRIBE:
            newState = Object.assign({}, state);
            subscriber = Object.assign({}, newState.subscriber);
            subscriber.subscriberId = action.payload;
            newState.subscriber = subscriber;
            return newState;

        case SAVE_SUBSCRIBER:
            newState = Object.assign({}, state);
            subscriber = Object.assign({}, newState.subscriber);
            subscriber.id = action.payload;
            newState.subscriber = subscriber;
            return newState;

        default:
            return state
    }
}

export default subscription
