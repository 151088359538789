/* tslint:disable */
/* eslint-disable */
/**
 * AppSparc.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthNetSubscriber
 */
export interface AuthNetSubscriber {
    /**
     * 
     * @type {string}
     * @memberof AuthNetSubscriber
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface ContactModel
 */
export interface ContactModel {
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactModel
     */
    'lastUpdated'?: string;
}
/**
 * 
 * @export
 * @interface PaymentInformation
 */
export interface PaymentInformation {
    /**
     * 
     * @type {number}
     * @memberof PaymentInformation
     */
    'subscriberId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentInformation
     */
    'nameOnCard'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentInformation
     */
    'cardNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentInformation
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentInformation
     */
    'cardCode'?: string | null;
}
/**
 * 
 * @export
 * @interface SubscriberInformation
 */
export interface SubscriberInformation {
    /**
     * 
     * @type {number}
     * @memberof SubscriberInformation
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriberInformation
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriberInformation
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriberInformation
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriberInformation
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriberInformation
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriberInformation
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriberInformation
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriberInformation
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriberInformation
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriberInformation
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriberInformation
     */
    'subscriberId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriberInformation
     */
    'applicationUrl'?: string | null;
}

/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ContactModel} [contactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactPost: async (contactModel?: ContactModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PaymentInformation} [paymentInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactSetupPaymentPost: async (paymentInformation?: PaymentInformation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Contact/SetupPayment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentInformation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubscriberInformation} [subscriberInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactSubscribePost: async (subscriberInformation?: SubscriberInformation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Contact/Subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriberInformation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ContactModel} [contactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactPost(contactModel?: ContactModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactPost(contactModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PaymentInformation} [paymentInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactSetupPaymentPost(paymentInformation?: PaymentInformation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthNetSubscriber>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactSetupPaymentPost(paymentInformation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SubscriberInformation} [subscriberInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactSubscribePost(subscriberInformation?: SubscriberInformation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactSubscribePost(subscriberInformation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactApiFp(configuration)
    return {
        /**
         * 
         * @param {ContactModel} [contactModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactPost(contactModel?: ContactModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiContactPost(contactModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PaymentInformation} [paymentInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactSetupPaymentPost(paymentInformation?: PaymentInformation, options?: any): AxiosPromise<AuthNetSubscriber> {
            return localVarFp.apiContactSetupPaymentPost(paymentInformation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubscriberInformation} [subscriberInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactSubscribePost(subscriberInformation?: SubscriberInformation, options?: any): AxiosPromise<number> {
            return localVarFp.apiContactSubscribePost(subscriberInformation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
    /**
     * 
     * @param {ContactModel} [contactModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public apiContactPost(contactModel?: ContactModel, options?: AxiosRequestConfig) {
        return ContactApiFp(this.configuration).apiContactPost(contactModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentInformation} [paymentInformation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public apiContactSetupPaymentPost(paymentInformation?: PaymentInformation, options?: AxiosRequestConfig) {
        return ContactApiFp(this.configuration).apiContactSetupPaymentPost(paymentInformation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubscriberInformation} [subscriberInformation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public apiContactSubscribePost(subscriberInformation?: SubscriberInformation, options?: AxiosRequestConfig) {
        return ContactApiFp(this.configuration).apiContactSubscribePost(subscriberInformation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ErrorApi - axios parameter creator
 * @export
 */
export const ErrorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ErrorApi - functional programming interface
 * @export
 */
export const ErrorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ErrorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.errorGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ErrorApi - factory interface
 * @export
 */
export const ErrorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ErrorApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorGet(options?: any): AxiosPromise<void> {
            return localVarFp.errorGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ErrorApi - object-oriented interface
 * @export
 * @class ErrorApi
 * @extends {BaseAPI}
 */
export class ErrorApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorApi
     */
    public errorGet(options?: AxiosRequestConfig) {
        return ErrorApiFp(this.configuration).errorGet(options).then((request) => request(this.axios, this.basePath));
    }
}


