import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {subscribe, updateSubscriberField} from "../../actions/subscription";
import toast from "react-hot-toast";
import WaitingIndicator from "../components/waiting-indicator";

export default function Subscribe () {

    const [nameOnCard, setNameOnCard] = React.useState('');
    const [cardNumber, setCardNumber] = React.useState('');
    const [expirationDate, setExpirationDate] = React.useState('');
    const [cardCode, setCardCode] = React.useState('');
    const subscriber = useSelector(state => state.subscription.subscriber);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!subscriber?.email || subscriber.email.length === 0) {
            navigate("/signup");
        }
    }, [navigate, subscriber]);

    const handleSubscribeToService = function (){
        if(!nameOnCard || !cardNumber || !expirationDate || !cardCode || !subscriber.terms){
            toast.error("All payment fields are required");
            return;
        }
        const paymentInfo = {nameOnCard, cardNumber, expirationDate, cardCode};
        dispatch(subscribe(subscriber, paymentInfo, navigate));
    }

    return (<div className={"row justify-content-center"}>
        <div className={"col-8 mt-5"}>
            <div className="card border-0 shadow my-5">
                <div className="card-header p-3 h4 text-center">
                    Enter Payment Information
                </div>
                <div className="card-body p-2">
                    <div className={"row mb-4"}>
                        <div className={"col"}>
                            <h5>Subscriber Information (<Link className={"btn btn-link btn-sm p-0"} to="/signup">edit</Link>)</h5>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            Company:
                        </div>
                        <div className={"col"}>
                            {subscriber.company}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            Name:
                        </div>
                        <div className={"col"}>
                            {subscriber.firstname} {subscriber.lastname}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            Phone Number:
                        </div>
                        <div className={"col"}>
                            {subscriber.phoneNumber}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            Email Address:
                        </div>
                        <div className={"col"}>
                            {subscriber.email}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            Phone Number:
                        </div>
                        <div className={"col"}>
                            {subscriber.phoneNumber}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            Address:
                        </div>
                        <div className={"col"}>
                            {subscriber.address}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            &nbsp;
                        </div>
                        <div className={"col"}>
                            {subscriber.city}, {subscriber.state} {subscriber.zip}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            Country:
                        </div>
                        <div className={"col"}>
                            {subscriber.country}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4 text-end fw-bold"}>
                            Application URL:
                        </div>
                        <div className={"col"}>
                            https://{subscriber.applicationUrl}.appsparc.com
                        </div>
                    </div>
                    <div className={"row my-4"}>
                        <div className={"col"}>
                            <h5>Payment Information</h5>
                        </div>
                    </div>
                    <div className={"row justify-content-center"}>
                        <div className={"col-6"}>
                            <div className="row">
                                <div className="col mb-3">
                                    <label>Name on card</label>
                                    <input type="text" className="form-control form-control-sm" value={nameOnCard}
                                           onChange={(e) => setNameOnCard(e.currentTarget.value)}/>
                                    <small className="text-muted">Full name as displayed on card</small>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="col mb-3">
                                    <label>Credit card number</label>
                                    <input type="text" className="form-control form-control-sm" value={cardNumber}
                                           onChange={(e) => setCardNumber(e.target.value)}/>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="col mb-3">
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label>Expiration</label>
                                            <input type="text" className="form-control form-control-sm"
                                                   placeholder="MMYY"
                                                   value={expirationDate}
                                                   onChange={(e) => setExpirationDate(e.target.value.replace("/", ""))}/>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label>CVV</label>
                                            <input type="text" className="form-control form-control-sm" value={cardCode}
                                                   placeholder={"123"}
                                                   onChange={(e) => setCardCode(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="col mb-3">
                                    <label className="form-check-label">
                                        <input name={"terms"} type="checkbox" className="form-check-input"
                                               checked={subscriber.terms}
                                               onChange={(e) => dispatch(updateSubscriberField(e.target.name, e.target.checked))}/>
                                        <span className={"small"}>
                                &nbsp;I agree to the Appsparc, LLC.&nbsp;
                                            <a href={"https://app.termly.io/document/privacy-policy/ed6551da-40eb-4c19-a782-1a6b89428cb4"}
                                               target={"_blank"} rel="noreferrer">privacy policy</a>,&nbsp;
                                            <a href={"https://app.termly.io/document/acceptable-use-policy/adf563c7-f8a0-48a2-8c6c-cfa906e68f24"}
                                               target={"_blank"}
                                               rel="noreferrer">acceptable use policy</a>,&nbsp;and&nbsp;
                                            <a href={"https://app.termly.io/document/terms-of-use-for-saas/0f867f28-9c6a-43b2-97f5-ede52e620647"}
                                               target={"_blank"} rel="noreferrer">terms and conditions.</a>&nbsp;&nbsp;The forms and documents available with this system are provided as templates for general informational purposes only.
                                            Appsparc makes no warranties, express or implied, regarding the accuracy, completeness, or suitability of these materials for any specific purpose.
                                            The use of these templates is at the user’s own risk, and Appsparc is not responsible or liable for any legal consequences that may arise from their use.
                                            Users are encouraged to consult with a qualified legal professional to ensure that the documents meet their specific needs and comply with applicable laws and regulations.
                                            </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"row mt-4"}>
                        <div className={"col text-end"}>
                            <button type={"button"}
                                    className={subscriber.terms ? "btn btn-sm btn-outline-primary" : "btn btn-sm btn-outline-primary disabled"}
                                    onClick={handleSubscribeToService}>Subscribe
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <WaitingIndicator/>
    </div>);
}
