import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "../home/home";
import Contact from "../contact/contact";
import Profile from "../profile";
import Registrations from "../registrations";
import SignUp from "../signup";
import Subscribe from "../subscribe";
import SubscribeSuccess from "../subscribe/success";
import TenThirtyOneExchange from "../exchange";

const AppRoutes = () => {
    return (
        <div>
            <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route exact path="/contact" element={<Contact/>}/>
                <Route exact path="/profile" element={<Profile/>}/>
                <Route exact path="/userlist" element={<Registrations/>}/>
                <Route exact path="/signup" element={<SignUp/>}/>
                <Route exact path="/subscribe" element={<Subscribe/>}/>
                <Route exact path="/success" element={<SubscribeSuccess/>}/>
                <Route exact path="/software" element={<TenThirtyOneExchange/>}/>
            </Routes>
        </div>
    )
}

export default AppRoutes;
