import {
    CLEAR_CONTACT_FORM,
    FORM_SUBMITTED,
    LOAD_REGISTRATIONS, SAVING_CONTACT_INFO,
    UPDATE_CONTACT_FIELD,
    VALIDATE_CONTACT
} from "./action-types";
import {baseUrl} from "../constants/settings";
import toast from "react-hot-toast";
import {ContactApi} from "../api";

const contactApi = new ContactApi(undefined, baseUrl);

export const updateContactField = (field, value) => {
// eslint-disable-next-line
    value = value.replace(/[^@\(\)\.\-\w\s]/gi, '');
    if (field === "name" && value.length > 50)
        value = value.substr(0, 50);
    if (field === "phone")
        value = value.replace(/[^[0-9]/gi, '');
    return dispatch => {
        dispatch({type: UPDATE_CONTACT_FIELD, payload: {field, value}});
    }
}

export const clearContactForm = () => {
    return dispatch => {
        dispatch({type: CLEAR_CONTACT_FORM});
    }
}

export const savingContactInfo = () => {
    return dispatch => {
        dispatch({type: SAVING_CONTACT_INFO});
    }
}

export const validateContactEmail = () => {
    return (dispatch, getState) => {
        const contact = getState().contact;
        const emailValid = contact.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        dispatch({type: VALIDATE_CONTACT, payload: emailValid});
    }
}

export const submitContactInfo = (contact) => {
    return async (dispatch, getState) => {
        const emailValid = getState().contact.emailValid;
        if (!emailValid) {
            toast.error("Please enter a valid email address");
            dispatch({type: FORM_SUBMITTED})
            return Promise.reject();
        }
        try {
            const response = await contactApi.apiContactPost(contact);
            toast("Contact information submitted successfully.  A member of our team will reach out to you.");
            dispatch({ type: CLEAR_CONTACT_FORM, payload: response.data});
        } catch(err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}

export const loadRegistrations = function() {
    return async dispatch => {
        try {
            const response = await contactApi.apiContactGet();
            dispatch({ type: LOAD_REGISTRATIONS, payload: response.data });
        } catch (err) {
            toast.error(err.message);
            console.log(err);
        }
    }
}