import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loadRegistrations} from "../../actions/contact";

const Registrations = () => {

    const dispatch = useDispatch();
    const registrations = useSelector(state => state.contact.registrations);

    useEffect(() => {
        dispatch(loadRegistrations());
    }, [dispatch]);

    const formatPhone = function (phone){
        if (phone.length < 10) return phone;

        const lastTen = phone.substring(phone.length - 10);
        const match = lastTen.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return phone.replace(lastTen, '') + ' (' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return phone;
    }

    const formatDate = function (date){
        const dt = new Date(date + "Z");
        return dt.toLocaleDateString() + " " + dt.toLocaleTimeString();
    }

    return (
        <div className="row m-0 p-0 justify-content-center">
            <div className="col-5">
                <div className="card border-0 shadow my-2">
                    <div className="card-header p-1">
                        <div className={"row m-0 p-0"}>
                            <div className={"col-9 m-0 p-0 pt-2 ps-2"}>
                                <h5>Registrations</h5>
                            </div>
                            <div className={"text-end col-3 m-0 p-0"}>
                                &nbsp;
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-4">
                        <table className={"table table-borderless"}>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th className={"text-end pe-3"}>Phone</th>
                                <th className={"text-end pe-3"}>Date Registered</th>
                            </tr>
                            {registrations.map((r, idx) => <tr key={idx}>
                                <td>{r.name}</td>
                                <td>{r.email}</td>
                                <td className={"text-end pe-3"}>{formatPhone(r.phone)}</td>
                                <td className={"text-end pe-3"}>{formatDate(r.lastUpdated)}</td>
                            </tr>)}
                        </table>
                    </div>
                </div>
            </div>
        </div>)
}

export default Registrations;